import * as React from "react"

import Layout from '../components/layout'
import Product from '../components/item'

// markup
const TVSeries = () => {
  return (
    <Layout>
      TVSeries
    </Layout>
  )
}

export default TVSeries
